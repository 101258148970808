table{
    width: 250px !important;
    height: 50px;
    border:3px solid blue;
}

/* .FormRegistrarPedido input:valid {
    background-color: transparent;
}

.FormRegistrarPedido input:invalid {
    background-color: lightpink;
} */