table{
    width: 250px !important;
    height: 50px;
    border:3px solid blue;
}

/* .FormRegistrarPedido input:valid {
    background-color: transparent;
}

.FormRegistrarPedido input:invalid {
    background-color: lightpink;
} */
:root {
  --header-height: 3.5rem;
  --navDesign-width: 410px;

  /*  Colors */
  --first-color: #6923d0;
  --first-color-light: #f4f0fa;
  --title-color: #19181b;
  --text-color: #58555e;
  --text-color-light: #a5a1aa;
  --body-color: #f9f6fd;
  --container-color: white;

  /*    Tipografia */
  --normal-font-size: 0.938rem;
  --small-font-size: 0.75rem;
  --smaller-font-size: 0.75rem;
  /* font weight */
  --font-medium: 500;
  --font-semi-bold: 600;

  /* z index */
  --z-fixed: 100;
}

@media screen and (min-width: 1024px) {
  :root {
    --normal-font-size: 1rem;
    --small-font-size: 0.875rem;
    --smaller-font-size: 0.813rem;
  }
}

/* Base */
*,
::before,
::after {
  box-sizing: border-box;
}

body {
  font-family: "Poppins", sans-serif;
  margin: var(--header-height) 0 0 0;
  padding: 1rem 1rem 0;
  font-size: var(--normal-font-size);
  background-color: var(--body-color);
  color: var(--text-color);
}

h3 {
  margin: 0;
}
a {
  text-decoration: none;
}

img {
  max-width: 100%;
  height: auto;
}
/* HEADER */
.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: var(--container-color);
  box-shadow: 0 1px 0 rgba(22, 8, 43, 0.1);
  padding: 0 1rem;
  z-index: var(--z-fixed);
}
.header__container {
  display: flex;
  align-items: center;
  height: var(--header-height);
  justify-content: space-between;
}
.header__img {
  object-fit: cover;
  cursor: pointer;
}
.header__logo {
  color: var(--title-color);
  font-weight: var(--font-medium);
}

.header__toggle {
  font-size: 1.2rem;
  color: var(--title-color);
  cursor: pointer;
}
/* FIN HEADER */

/*  navDesign */
.navDesign {
  position: fixed;
  top: 0;
  left: -100%;
  height: 100vh;
  padding: 1rem 1rem 3rem;
  background-color: var(--container-color);
  box-shadow: 1px 0 0 rgba(22, 8, 43, 0.1);
  z-index: var(--z-fixed);
  transition: 0.4s;
  /*  width: 320px; */
}
/* mostrar menu */
.show-menu {
  left: 0;
}

.navDesign__container {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: auto;
  scrollbar-width: none; /* mozilla */
  width: 100%;
}
.navDesign__container::-webkit-scrollbar {
  display: none;
}
.navDesign__logo {
  font-weight: var(--font-semi-bold);
  margin-bottom: 2.5rem;
}
.navDesign__list,
.navDesign__items {
  display: grid;
}

.navDesign__list {
  margin-bottom: 2.5rem;
}

.navDesign__items {
  grid-row-gap: 1.5rem;
  row-gap: 1.5rem;
}
.navDesign__subtitle {
  font-size: var(--normal-font-size);
  text-transform: uppercase;
  letter-spacing: 0.1rem;
  color: var(--text-color-light);
}

.navDesign__link {
  display: flex;
  align-items: center;
  color: var(--text-color);
  transition: all 0.4s ease;
}

.navDesign__link:hover {
  color: var(--first-color);
}

.navDesign__icon {
  font-size: 1.2rem;
  margin-right: 0.5rem;
}

.navDesign__name {
  font-size: var(--small-font-size);
  font-weight: var(--font-medium);
  white-space: nowrap;
  transition: all 0.3s ease-in-out;
}
.navDesign__name:hover {
  color: #6923d0;
}
.navDesign__logout {
  margin-top: 5rem;
}
/*  FIN navDesign */

/* DROPDOWN */
.navDesign__dropdown {
  overflow: hidden;
  max-height: 21px;
  transition: all 0.5s ease-in-out;
  width: 100%;
}

.navDesign__dropdown-collapse {
  background-color: var(--first-color-light);
  border-radius: 0.25rem;
  margin-top: 1rem;
}
.navDesign__dropdown-content {
  display: grid;
  grid-row-gap: 0.5rem;
  row-gap: 0.5rem;
  /*   padding: 0.75rem 2.5rem 0.75rem 1.8rem; */
  padding: 8px 8px;
}
.navDesign_dropdown-item {
  font-size: var(--smaller-font-size);
  font-weight: var(--font-medium);
  color: var(--text-color);
  transition: all 0.2s ease;
}
.navDesign_dropdown-item:hover {
  color: var(--first-color);
}
.navDesign__dropdown-icon {
  margin-left: auto;
  transition: all 0.5s ease-in-out;
}

/* mostrar collapse */
.navDesign__dropdown:hover {
  max-height: 100rem;
}

/* ROTAR ICONO */
.navDesign__dropdown:hover .navDesign__dropdown-icon {
  transform: rotate(180deg);
}

/* activar link */
.active {
  color: var(--first-color);
}

/* media queries */

@media screen and (min-width: 768px) {
  body {
    padding: 1rem 3rem 0 6rem;
  }
  .header {
    padding: 0 3rem 0 6rem;
  }
  .header__container {
    height: calc(var(--header-height) + 0.5rem);
  }
  .header__toggle {
    display: none;
  }
  .header__img {
    order: 1;
  }
  .navDesign {
    left: 0;
    padding: 1.2rem 1.5rem 3rem;
    width: 68px;
  }
  .navDesign__items {
    grid-row-gap: 1.7rem;
    row-gap: 1.7rem;
  }
  .navDesign__icon {
    font-size: 1.3rem;
  }
  .navDesign__logo-name,
  .navDesign__name,
  .navDesign__subtitle,
  .navDesign__dropdown-icon {
    opacity: 0;
  }
  .navDesign:hover {
    width: var(--navDesign-width);
  }
  .navDesign:hover .navDesign__logo-name {
    opacity: 1;
  }
  .navDesign:hover .navDesign__name {
    opacity: 1;
  }
  .navDesign:hover .navDesign__subtitle {
    opacity: 1;
  }
  .navDesign:hover .navDesign__dropdown-icon {
    opacity: 1;
  }
}
@media screen and (max-width: 450px) {
  .header__img {
    display: none;
  }
}


